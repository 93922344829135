.App {
  text-align: center;
  background-image: url("../public/background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

header {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
}

.logo {
  width: 40px;
  height: 40px;
  padding-right: 20px;
}

.leftH {
  display: flex;
  align-items: center;
  gap: 20px;
}

.rightH {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.headerItem {
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 20px;
  transition: 0.3s;
  display: flex;
  align-items: center;
}

.headerItem:hover {
  background-color: rgb(34, 42, 58);
  cursor: pointer;
}

.connectButton {
  background-color: #ffffff;
  padding: 10px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 100px;
  color: #000000;
  font-weight: 600;
  transition: 0.3s;
}

.connectButton1 {
  background-image: linear-gradient(127deg, #1e1e1e, #642f7b, #35004b);
  padding: 10px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 100px;
  color: white;
  font-weight: 600;
  transition: 0.3s;
}

.connectButton:hover {
  cursor: pointer;
  color: #3b4874;
}

.mainWindow {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.link {
  text-decoration: none;
  color: white;
}

.tradeBox {
  width: 400px;
  background-color: #000000;
  border: 2px solid #ffffff;
  min-height: 300px;
  border-radius: 15px;
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 30px;
  padding-right: 30px;
}

.tradeBoxHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
}
.tradeBoxHeader h4 {
  color: #ffffff;
}
.cog {
  color: #51586f;
  transition: 0.3s;
}

.cog:hover {
  color: white;
  rotate: 90deg;
  cursor: pointer;
}

.switchButton {
  background-color: #000000;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 8px;
  position: absolute;
  top: 86px;
  left: 180px;
  color: #5f6783;
  border: 3px solid #0e111b;
  font-size: 12px;
  transition: 0.3s;
}

.dollarAmount {
  position: absolute;
  top: 75px;
  left: 14px;
  font-size: 10px;
  color: #5f6783;
}

.switchButton:hover {
  color: white;
  cursor: pointer;
}

.inputs {
  position: relative;
}

.assetOne {
  position: absolute;
  min-width: 50px;
  height: 30px;
  background-color: #000000;
  top: 26px;
  right: 20px;
  border-radius: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  font-size: 17px;
  padding-right: 8px;
  color: #ffffff;
}

.assetOneBalance {
  position: absolute;
  min-width: 50px;
  color: #5f6783;
  top: 70px;
  right: 20px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  font-size: 13px;
  padding-right: 8px;
}

.assetTwoBalance {
  position: absolute;
  min-width: 50px;
  color: #5f6783;
  top: 170px;
  right: 20px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  font-size: 13px;
  padding-right: 8px;
}

.assetTwo {
  position: absolute;
  min-width: 50px;
  height: 30px;
  background-color: #000000;
  top: 125px;
  right: 20px;
  border-radius: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  font-size: 17px;
  padding-right: 8px;
  color: #ffffff;
}

.assetOne:hover {
  cursor: pointer;
}

.assetTwo:hover {
  cursor: pointer;
}

.assetLogo {
  height: 22px;
  margin-left: 5px;
}

.swapButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  width: calc(100%);
  height: 55px;
  font-size: 20px;
  border-radius: 12px;
  color: #000000;
  font-weight: bold;
  transition: 0.3s;
  margin-bottom: 30px;
  margin-top: 8px;
}

.swapButton[disabled] {
  background-color: #ffffff;
  opacity: 0.4;
  color: #000000;
}

.swapButton[disabled]:hover {
  cursor: not-allowed;
  background-color: #ffffff;
}

.swapButton:hover {
  cursor: pointer;
  background-color: #ffffff;
}

.tokenLogo {
  height: 40px;
  width: 40px;
}

.modal {
  margin: 0 auto;
  position: absolute;
  /* top: 50%;
  bottom: 50%; */
  /* right: 51.7%;
  left: 48.3%; */
  background-color: #000000;
  /* margin-left: 51.3em; */
  margin-top: 2.2em;
  height: 80vh;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: scroll;
}

.abc {
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  /* display: grid;
  place-items: center; */
  background-color: #a39e9e;
}

.modal i {
  position: absolute;
  top: 75px;
  right: 30px;
  width: 30px;
  height: 30px;
  /* background-color: #fff; */
}

.address {
  width: 90%;
  height: 5vh;
  font-size: 12px;
  padding-left: 10px;
  border-radius: 5px;
  background-color: #ffffff00;
  margin-left: 1rem;
  margin-top: 1rem;
}

.modalContent {
  border-top: 1px solid #363e54;
  /* padding-top: 20px; */
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tokenChoice {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tokenChoice:hover {
  cursor: pointer;
  background-color: #1f2639;
}

.tokenName {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
}

.tokenTicker {
  margin-left: 10px;
  font-size: 13px;
  font-weight: 300;
  color: #ffffff;
}
.max {
  color: #6a0f91;
  font-weight: 700;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .tradeBox {
    width: 280px;
  }
  .switchButton {
    left: 150px;
  }
}

@media screen and (min-width: 1200px) {
  .modal {
    right: 51.7%;
    left: 48.3%;
  }
}

@media screen and (max-width: 1200px) {
  .modal {
    right: 40.7%;
    left: 40.3%;
  }
}
@media screen and (max-width: 767px) {
  .modal {
    right: 25.7%;
    left: 25.3%;
  }
}
@media screen and (max-width: 550px) {
  .modal {
    right: 5.7%;
    left: 5.3%;
  }
}

#dexscreener-embed {
  position: relative;
  width: 100%;
  padding-bottom: 125%;
}
@media (min-width: 1400px) {
  #dexscreener-embed {
    padding-bottom: 65%;
  }
}
#dexscreener-embed iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
}

@media (max-width: 1000px) {  /* Or any other breakpoint you consider "mobile" */
  .mobile-column-direction {
    flex-direction: column;
  }

  .mobile-graph {
    width: 300px;
    height: 275px;
    display: none;
  }
}
